import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getMiscellaneousData = createAsyncThunk(
	'miscellaneousData/getMiscellaneousData',
	async () => {
        const response = await fetch(`${process.env.GATSBY_WP_SITEURL}/wp-json/twentytwo/api/v1/globals-settings/?name=miscellaneous`)
			            .then((response) => response.json());

        return response;
	}
)


export const getGlobalsSettingsData = createAsyncThunk(
	'globalsetting/getGlobalsSettingsData',
	async () => {
        const response = await fetch(`${process.env.GATSBY_WP_SITEURL}/wp-json/twentytwo/api/v1/globals-settings`)
			            .then((response) => response.json());

        return response;
	}
)

export const getProeprtyTypes = createAsyncThunk(
	'property/getProeprtyTypes',
	async () => {
        let apiUrl = `${process.env.HIVE_API_URL}/commercial_property_type?per_page=100&_fields=id,slug,name,parent&parent=0`
        const response = await fetch(apiUrl)
			                  .then((response) => response.json());
        return response;
	}
)

const commonState = createSlice({
	name: 'commonState',
	initialState: {
		googleScriptLoaded : false,
		loading: false,
		miscellaneousData: {},
		globalsetting: {},

		propertyTypeLoading: false,
		propertyTypes: {}

	},
	reducers: {
		updateGoogleScriptState: (state, action) => {
			const isLoaded = action.payload;
			state.googleScriptLoaded = isLoaded
		},

	},
	extraReducers: {
        [getMiscellaneousData.pending]: (state) => {
            state.loading = true
        },
        [getMiscellaneousData.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.miscellaneousData = payload?.data
        },
        [getMiscellaneousData.rejected]: (state) => {
            state.loading = false
        },

		[getGlobalsSettingsData.pending]: (state) => {
            state.loading = true
        },
        [getGlobalsSettingsData.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.globalsetting = payload?.data
        },
        [getGlobalsSettingsData.rejected]: (state) => {
            state.loading = false
        },

		[getProeprtyTypes.pending]: (state) => {
			// console.log('getProeprtyTypes started')
			state.propertyTypeLoading = true
		},
		[getProeprtyTypes.fulfilled]: (state, {payload}) => {
		//   console.log('getProeprtyTypes completed', payload)
			state.propertyTypeLoading = false
			state.propertyTypes = payload
		},
		[getProeprtyTypes.rejected]: (state) => {
		//   console.log('getProeprtyTypes rejected')
			state.propertyTypeLoading = false
		},
    },
})

export const { updateGoogleScriptState } = commonState.actions

export default commonState.reducer;
