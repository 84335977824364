import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { queryParser } from "../../utils";

// export const getProeprtyTypes = createAsyncThunk(
// 	'property/getProeprtyTypes',
// 	async () => {
//         let apiUrl = `${process.env.HIVE_API_URL}/property_type?per_page=100&_fields=id,slug,name&parent=0`
//         const response = await fetch(apiUrl)
// 			                  .then((response) => response.json());
//         return response;
// 	}
// )
let browserURLObject =  {};

if( typeof window !== "undefined") {
	browserURLObject = queryParser(window.location.search);
}


const propertySearchSlice = createSlice({
	name: "property-search",
	initialState: {
		data: {},
		settings: {
			department: browserURLObject?.department ?  browserURLObject?.department :  "commercial",
			commercial_for_sale_to_rent: browserURLObject?.commercial_for_sale_to_rent ?  browserURLObject?.commercial_for_sale_to_rent :  "for_sale",
			page: browserURLObject?.page ?  browserURLObject?.page : 1,
			radius: browserURLObject?.radius ?  browserURLObject?.radius :  '',
			per_page: browserURLObject?.per_page ?  browserURLObject?.per_page : 13,
			orderby: browserURLObject?.orderby ?  browserURLObject?.orderby : "price-desc",
			view: typeof window !== "undefined" && window.matchMedia("(max-width: 768px)").matches  ? "grid" : "list",
			// commercial_maximum_price:  browserURLObject?.commercial_maximum_price ?  browserURLObject?.commercial_maximum_price : 999999999999,
			// commercial_minimum_price: browserURLObject?.commercial_minimum_price ?  browserURLObject?.commercial_minimum_price : 0
		},
		pgp:'',
		// propertyTypes: {}
	},
	reducers: {
		updateSearchState: (state, action) => {
			// console.log("REDUX: UPDATE PROPERY SEARCH STATE", action.payload)
			state.settings = action.payload
		},
		updatePgpPathState: (state, action) => {
			const value = action.payload;
			state.pgp = value
		}
	},
	// extraReducers: {

	// [getProeprtyTypes.pending]: (state) => {
	//     // console.log('getProeprtyTypes started')
	//     state.loading = true
	// },
	// [getProeprtyTypes.fulfilled]: (state, {payload}) => {
	//   console.log('getProeprtyTypes completed', payload)
	//     state.loading = false
	//     state.propertyTypes = payload
	// },
	// [getProeprtyTypes.rejected]: (state) => {
	//   // console.log('getProeprtyTypes rejected')
	//     state.loading = false
	// },
// },
})

export const { updateSearchState, updatePgpPathState } = propertySearchSlice.actions
export default propertySearchSlice.reducer
